@import "designSystem/colors.scss";
// Default theme. ~960B
@import '~@vime/core/themes/default.css';

// Optional light theme (extends default). ~400B
@import '~@vime/core/themes/light.css';

.video-container {


    .video-player {
        position: relative;
    }

    .load-permissions {
        position: absolute;
        background-size: cover !important;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        background-color: transparent;
    }

    .load-permissions:hover {
        cursor: pointer;
    }

    .information {
        margin-top: 10px;
        margin-bottom: 30px;
        .title {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
        }


        .date {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 148.9%;
            color: #535353;
        }

        .description {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }
    }
}
