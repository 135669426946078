@import "designSystem/colors.scss";

.videos-wrapper {
    padding: 40px;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;

    .pagination-bottom {
        margin-bottom: 50px;
        margin-top: 20px;
    }

    .subcategory {
        margin-bottom: 60px;
        .section-title {
            button {
                margin-left: 20px;

                svg{
                    margin-right: 10px;
                }
            }
            margin-bottom: 30px!important;
        }
        .empty {
            padding: 20px;
        }
    }
}
