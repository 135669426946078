@import "designSystem/colors.scss";

.youtube-hidden-wrapper {
    padding: 40px;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;

    .pagination-bottom {
        margin-bottom: 50px;
        margin-top: 20px;
    }
    
    .section-title {
        button {
            margin-left: 20px;

            svg{
                margin-right: 10px;
            }
        }
        margin-bottom: 30px!important;
    }
    .empty {
        padding: 20px;
    }
}
