@import "designSystem/colors.scss";

.youtube-video  {

    margin-bottom: 50px;

    .video {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .information {
        margin-top: 10px;
        margin-bottom: 30px;
        .title {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
        }


        .date {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 148.9%;
            color: #535353;
        }
        
        .description {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }
    }
}