@import "designSystem/colors.scss";

.menu-accordion {

    .menu-category {
        a {
            color: #212529;
            text-decoration: none;
        }

        .accordion-button::after {
            display: none;
        }
    }

    

}