/*@import "./src/designSystem/colors.scss";

.login-component {
    display: flex;
    flex-direction: column;
    width: 100%;

    form {
        width: 100%;

        div.material-input-component:first-of-type {
            margin-bottom: 40px;
        }

        .forgot-password {
            display: block;
            text-decoration: none;
            color: $font-secondary;
            width: 100%;
            margin-bottom: 30px;
            margin-top: 10px;
            text-align: right;
            font-size: 12px;
            font-weight: 600;
        }

        button {
            margin-top: 20px;
        }
    }
}
*/

.auth-layout {
    .qr-container {
        text-align: center;
        margin-top: 25px;
    }
    .waiting-qr {
        height: 200px;
        margin-top: 30px;
    }
    .login-component {
        .login-title {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 20.987px;
            line-height: 26px;
            /* identical to box height */


            color: #000000;
            margin-bottom: 15px;
        }

        .login-subtitle {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 165.4%;
            /* or 26px */

            letter-spacing: 0.15px;

        }
    }
}